import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { useContext } from 'react';
import { ISplitContextValues } from '@splitsoftware/splitio-react/types/types';
import { useAuthenticator } from '@aws-amplify/ui-react';

export enum Feature {
    USERS_GLOBAL_ADMIN = 'Users-Global-Admin',
    COMPANIES_GLOBAL_ADMIN = 'Companies-Global-Admin',
}

export interface FeatureFlagsWithConfig {
    [featureName: string]: FeatureFlagWithConfig;
}

export interface FeatureFlagWithConfig {
    isReady: boolean;
    isOn: boolean;
    treatment: string;
    config: string | null;
}

export function generateFlags(treatments: SplitIO.TreatmentsWithConfig, splitContext: ISplitContextValues): FeatureFlagsWithConfig {
    const flagsWithConfig: FeatureFlagsWithConfig = {};

    Object.keys(treatments).forEach((featureName) => {
        const value = treatments[featureName];

        flagsWithConfig[featureName] = {
            ...splitContext,
            ...value,
            isOn: value.treatment === 'on',
        };
    });

    return flagsWithConfig;
}

export function useFeatureFlags(flagNames: string[]): FeatureFlagsWithConfig {
    const { user } = useAuthenticator((context) => [context.user]);
    const splitContext: ISplitContextValues = useContext(SplitContext);
    const splitAttributes: SplitIO.Attributes = {
        email: user.attributes?.email || 'missing-email',
        companyType: user.attributes?.companyType || 'missing-companyType',
        userId: user.attributes?.userId || 'missing-userId',
        companyId: user.attributes?.companyId || 'missing-companyId',
    };
    const treatments: SplitIO.TreatmentsWithConfig = useTreatments(flagNames, splitAttributes);
    const flagsWithConfig: FeatureFlagsWithConfig = generateFlags(treatments, splitContext);

    return flagsWithConfig;
}
