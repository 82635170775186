import React, { useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Table } from 'antd';
import { CloseCircleTwoTone, DeleteTwoTone, EditTwoTone, SaveOutlined, SaveTwoTone, UserAddOutlined } from '@ant-design/icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useCreateUserMutation, useDeleteUserMutation, User, UsersDocument, useUpdateUserMutation, useUsersQuery } from '../graphql/generated';
import { EditableCell } from '../EditableCell/EditableCell';

export default function Users() {
    // State and Server Data
    const { loading, data } = useUsersQuery({ variables: { limit: 5 } });
    const [mutateUser] = useUpdateUserMutation({ refetchQueries: [UsersDocument] });
    const [saveNewUser] = useCreateUserMutation({ refetchQueries: [UsersDocument] });
    const [deleteUserMutation] = useDeleteUserMutation({ refetchQueries: [UsersDocument] });
    const [editingKey, setEditingKey] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    // Functions for components
    const isEditing = (record: User) => record.id === editingKey;
    const edit = (record: Partial<User>) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id || '');
    };
    const cancel = () => {
        setEditingKey('');
    };
    const handleOk = async () => {
        const row = (await addForm.validateFields()) as User;
        // TODO: Give a Group picker
        saveNewUser({ variables: { input: { firstName: row.firstName, lastName: row.lastName, email: row.email, groups: ['Administrator'] } } });
        addForm.resetFields();
        setIsModalVisible(false);
    };
    const handleModalCancel = () => {
        addForm.resetFields();
        setIsModalVisible(false);
    };
    const handleDeleteUser = async (id: string) => {
        await deleteUserMutation({ variables: { id } });
    };
    const save = async (id: string) => {
        const row = (await form.validateFields()) as User;
        await mutateUser({ variables: { id, input: { lastName: row.lastName, firstName: row.firstName } } });
        setEditingKey('');
    };

    // Table Definitions and Merge function
    const columns = [
        { dataIndex: 'id', title: 'ID', responsive: ['md'] as Breakpoint[] },
        {
            dataIndex: 'firstName',
            title: 'First name',
            editable: true,
        },
        {
            dataIndex: 'lastName',
            title: 'Last name',
            editable: true,
        },
        {
            dataIndex: 'email',
            title: 'Email',
            editable: false,
        },
        {
            title: 'Actions',
            responsive: ['md'] as Breakpoint[],
            dataIndex: 'operation',
            render: (_: unknown, record: User) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <SaveTwoTone style={{ fontSize: 20, paddingRight: 5 }} onClick={() => save(record.id)} />
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <CloseCircleTwoTone style={{ fontSize: 20 }} />
                        </Popconfirm>
                    </span>
                ) : (
                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                        <EditTwoTone style={{ fontSize: 20, paddingRight: 5 }} disabled={editingKey !== ''} onClick={() => edit(record)} />
                        <Popconfirm title="Are you sure you want to Delete?" onConfirm={() => handleDeleteUser(record.id)}>
                            <DeleteTwoTone style={{ fontSize: 20 }} disabled={editingKey !== ''} />
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: User) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <Form form={form} component={false}>
                <Button
                    icon={<UserAddOutlined />}
                    onClick={() => {
                        setIsModalVisible(true);
                    }}
                    type="primary"
                    style={{ marginBottom: 16 }}
                >
                    Add New User
                </Button>
                <Table
                    rowKey="id"
                    components={{ body: { cell: EditableCell } }}
                    loading={loading}
                    bordered
                    pagination={{ onChange: cancel }}
                    dataSource={data ? data.users.items : []}
                    columns={mergedColumns}
                />
            </Form>
            <Modal
                title="Add User"
                visible={isModalVisible}
                onOk={handleOk}
                okText="Save"
                onCancel={handleModalCancel}
                okButtonProps={{ icon: <SaveOutlined /> }}
            >
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={addForm} component={false}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { type: 'email', message: 'Not a Valid Email' },
                            {
                                required: true,
                                message: `Email is Required`,
                            },
                        ]}
                    >
                        <Input placeholder="name@example.com" />
                    </Form.Item>

                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: `First Name is Required`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: `Last Name is Required`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
