import React from 'react';
import { Table } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useCompaniesQuery } from '../graphql/generated';

export default function Companies() {
    // State and Server Data
    const { loading, data } = useCompaniesQuery({ variables: { limit: 100 } });

    // Table Definitions
    const columns = [
        { dataIndex: 'id', title: 'ID', responsive: ['md'] as Breakpoint[] },
        {
            dataIndex: 'name',
            title: 'Company Name',
            editable: false,
        },
        {
            dataIndex: 'domainName',
            title: 'Domain Name',
            editable: false,
        },
    ];

    return <Table rowKey="id" loading={loading} bordered dataSource={data ? data.companies.items : []} columns={columns} />;
}
