import React, { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { Spin } from 'antd';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { components } from './AuthCustomComponents';
import PersistentDrawerLeft from './Drawer/Drawer';
import './Auth.css';

function App() {
    const [loading, setLoading] = useState<boolean>(true);
    fetch(
        `https://${process.env.REACT_APP_LOCAL_DEV_URL || window.location.host}/api/user-pool-settings?domainName=${
            process.env.REACT_APP_LOCAL_DEV_URL || window.location.hostname
        }`,
    )
        .then((res) => res.json())
        .then((json) => {
            Amplify.configure({
                Auth: {
                    region: json.region,
                    userPoolId: json.userPoolId,
                    userPoolWebClientId: json.userPoolClientId,
                },
            });
            setLoading(false);
        });
    return loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <Spin size="large" />
        </div>
    ) : (
        <Authenticator loginMechanisms={['email']} components={components} hideSignUp>
            {({ user }) => {
                const httpLink = new HttpLink({
                    uri: process.env.REACT_APP_LOCAL_DEV_URL ? `https://${process.env.REACT_APP_LOCAL_DEV_URL}/graphql` : '/graphql',
                });
                const authMiddleware = setContext(async (_, { headers }) => {
                    const currentSession = await Auth.currentSession();
                    return {
                        headers: {
                            ...headers,
                            Authorization: currentSession ? `Bearer ${currentSession.getIdToken().getJwtToken()}` : null,
                        },
                    };
                });
                const client = new ApolloClient({
                    cache: new InMemoryCache(),
                    link: ApolloLink.from([authMiddleware, httpLink]),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'cache-and-network',
                        },
                    },
                });
                const sdkConfig: SplitIO.IBrowserSettings = {
                    core: {
                        authorizationKey: process.env.REACT_APP_SPLIT_IO_AUTHKEY as string,
                        key: user.username ? user.username : 'no-user-name',
                    },
                };
                return (
                    <SplitFactory config={sdkConfig}>
                        <ApolloProvider client={client}>
                            <BrowserRouter>
                                <PersistentDrawerLeft />
                            </BrowserRouter>
                        </ApolloProvider>
                    </SplitFactory>
                );
            }}
        </Authenticator>
    );
}

export default App;
